<template>
  <div>
    <b-card>
      <tabs></tabs>
    </b-card>
  </div>
</template>

<script>
import Tabs from './tabs/Tabs.vue'

export default {
  name: 'shippers-edit',
  components: { Tabs }
}
</script>